/*
    This file is valid in both Javascript and Typescript
*/
import { extendTheme } from '@chakra-ui/react';

// UTILS

const disabledStyles = {
    background: '#D3D3D3',
    color: '#3F3F46',
};

// COMPONENTS

const Tabs = {
    variants: {
        group: {
            tab: {
                border: '1px solid #A1A1AA',
                borderRight: 'none',
                borderRadius: '4px',
                _last: {
                    borderRight: '1px solid #A1A1AA',
                },
                _selected: {
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                    bg: '#E4E4E7',
                    border: '2px solid #A1A1AA',
                    borderRight: '1px solid #A1A1AA',
                    _last: {
                        borderRight: '2px solid #A1A1AA',
                    },
                },
            },
            tabpanel: {
                padding: 0,
                height: '100%',
            },
        },
    },
};

const Box = {
    baseStyle: {
        backgroundColor: 'brand.blue',
    },
};

const Button = {
    baseStyle: {
        borderRadius: '4px',
    },
    variants: {
        primary: {
            background: '#000000',
            color: '#FFFFFF',
            _active: {
                background: '#3F3F46',
            },
            _disabled: disabledStyles,
            _hover: {
                background: '#3F3F46',
                _disabled: disabledStyles,
            },
        },
        secondary: {
            background: 'brand.gray',
            color: '#000000',
            _active: {
                background: '#E4E4E7',
            },
            _disabled: disabledStyles,
            _hover: {
                background: '#E4E4E7',
                _disabled: disabledStyles,
            },
        },
        login: {
            width: '100%',
            bg: 'brand.red',
            color: 'white',
            boxShadow:
                '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 1px -2px rgba(0, 0, 0, 0.121569), 0px 2px 2px rgba(0, 0, 0, 0.141176);',
            borderRadius: 'none',
            marginTop: '32px',
            marginBottom: '64px',
        },
    },
};

const Checkbox = {
    baseStyle: {
        control: {
            width: '24px',
            height: '24px',
            borderRadius: '0px',
            _checked: {
                bg: '#000000',
                _hover: { bg: '#000000' },
            },
        },
    },
};

const Radio = {
    baseStyle: {
        control: {
            backgroundColor: 'white',
            borderColor: 'black',
            _disabled: {
                borderColor: 'gray.400',
                backgroundColor: 'inherit',
            },
            _checked: {
                color: 'black',
                backgroundColor: 'inherit',
                borderColor: 'black',
                _hover: {
                    backgroundColor: 'inherit',
                    borderColor: 'black',
                },
            },
        },
    },
};

const Container = {
    variants: {
        login: {
            boxSizing: 'border-box',
            width: '400px',
            maxWidth: '650px',
            paddingBottom: '32px',
            background: '#FFFFFF',
            border: '1px solid #EFEFEF',
            boxShadow:
                '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 3px 14px 2px rgba(0, 0, 0, 0.121569), 0px 8px 10px 1px rgba(0, 0, 0, 0.141176)',
        },
    },
};

const Input = {
    variants: {
        styled: {
            field: {
                borderRadius: '0px',
                border: '1px solid',
                borderColor: '#D3D3D3',
                marginBottom: '16px',
            },
        },
    },
    defaultProps: {
        variant: 'styled',
    },
};

const Link = {
    variants: {
        loginHeaderActive: {
            width: '50%',
            padding: '8px 0',
            textAlign: 'center',
            _hover: { textDecoration: 'none', fontWeight: 'bold' },
            color: 'brand.blue',
            borderBottom: '2px solid',
            borderBottomColor: 'brand.blue',
        },
        loginHeaderInactive: {
            width: '50%',
            padding: '8px 0',
            textAlign: 'center',
            _hover: { textDecoration: 'none', fontWeight: 'bold' },
            borderBottom: '1px solid',
            borderBottomColor: '#F0F0F0',
        },
        main: {
            textDecoration: 'underline',
        },
    },
};

const Text = {
    variants: {
        okta: {
            fontFamily: 'SF Pro',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '13px',
            lineHeight: '16px',
            textAlign: 'center',
            color: '#989898',
        },
        passwordReset: {
            textAlign: 'center',
            width: '100%',
            margin: 'none',
            marginStart: [0, '-0.5rem, !important'],
            padding: '8px 0',
        },
        small: {
            weight: '400',
            size: '14px',
            lineHeight: '21px',
            color: '#52525B',
        },
        xsmall: {
            weight: '600',
            size: '12px',
            lineHeight: '18px',
            color: '#52525B',
        },
        smBold: {
            weight: '600',
            size: '14px',
            lineHeight: '17px',
            color: '#18181B',
        },
        mdBold: {
            weight: '800',
            size: '16px',
            lineHeight: '19px',
            color: '#18181B',
        },
    },
};

const VStack = {
    variants: {
        sidebar: {
            maxWidth: '250px',
        },
    },
};

const theme = extendTheme({
    components: {
        Box,
        Button,
        Checkbox,
        Radio,
        Container,
        Input,
        Link,
        Text,
        VStack,
        Tabs,
    },
    fonts: {
        heading: `'Inter', sans-serif`,
        body: `'Inter', sans-serif`,
    },
    colors: {
        brand: {
            red: '#C51746',
            blue: '#00517F',
            gray: '#F4F4F5',
        },
        neutral: {
            700: '#3F3F46',
            900: '#18181B',
        },
        yellow: { 100: '#FEF7E7' },
        black: '#000000',
    },
});

export default theme;
